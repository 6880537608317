import { ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import * as fromRouter from "@ngrx/router-store";
import { localStorageSync } from "ngrx-store-localstorage";
import { dashboardFeatureKey } from "app/modules/dashboard/reducers";
import {
	customReducerKeys,
	ItemKey,
} from "app/modules/dashboard/reducers/selection/keys";

import { environment } from "../../environments/environment";

export interface State {
	router: fromRouter.RouterReducerState;
}

export const reducers: ActionReducerMap<State> = {
	router: fromRouter.routerReducer,
};

export const logger = (reducer: ActionReducer<State>): ActionReducer<State> => {
	return (state: State, action: any): State => {
		return reducer(state, action);
	};
};

export const localStorageSyncReducer = (
	reducer: ActionReducer<State>,
): ActionReducer<State> => {
	const pagingKeys = [...Object.values(ItemKey), ...customReducerKeys];
	const entitiesToLocalStorage = pagingKeys.reduce((result, current) => {
		// store only page settings
		result[current] = [
			"header_queries",
			"page",
			"page_size",
			"query",
			"result_type",
			"title",
		];
		return result;
	}, {});
	return localStorageSync({
		keys: [
			{
				[dashboardFeatureKey]: [
					{
						paging: [
							{ entities: [entitiesToLocalStorage] },
							{ selections: pagingKeys },
						],
					},
				],
			},
		],
		rehydrate: true,
	})(reducer);
};

export const metaReducers: MetaReducer<State>[] = environment.production
	? [localStorageSyncReducer]
	: [logger, localStorageSyncReducer];
