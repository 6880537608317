import { isDevMode, NgModule } from "@angular/core";
import {
	TranslocoModule,
	provideTransloco,
	translocoConfig,
} from "@jsverse/transloco";
// There is an issue with the plugin at the moment where it causes an error
// NullInjectorError: No provider for TranslocoPreloadLangsService!
// import { provideTranslocoPreloadLangs } from "@ngneat/transloco-preload-langs";

import { TranslocoHttpLoader } from "./http-loader";
import { HttpClientModule } from "@angular/common/http";

export const translocoConf = translocoConfig({
	defaultLang: "en",
	availableLangs: ["en", "fr"],
	fallbackLang: "en",
	// Remove this option if your application doesn't support changing language in runtime.
	reRenderOnLangChange: true,
	// Do not remove this, it allows missed keys in anything not 'en' to default back to 'en'
	missingHandler: {
		// It will use the first language set in the `fallbackLang` property
		useFallbackTranslation: true,
	},
	// Remove this option if your application doesn't support changing language in runtime.
	prodMode: !isDevMode(),
});

@NgModule({
	exports: [TranslocoModule],
	imports: [HttpClientModule],
	providers: [
		provideTransloco({
			config: translocoConf,
			loader: TranslocoHttpLoader,
		}),
		// There is an issue with the plugin at the moment where it causes an error
		// NullInjectorError: No provider for TranslocoPreloadLangsService!
		// provideTranslocoPreloadLangs(["en"]),
	],
})
export class TranslocoRootModule {}
