import { Component, ChangeDetectionStrategy } from "@angular/core";

export const RootSelector = "app-root";

@Component({
	selector: RootSelector,
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="app-container">
			<router-outlet class="app-root"></router-outlet>
		</div>
	`,
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppComponent {}
