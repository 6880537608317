import { NgModule, inject } from "@angular/core";
import {
	Routes,
	RouterModule,
	ExtraOptions,
	RouterStateSnapshot,
	ActivatedRouteSnapshot,
	Router,
} from "@angular/router";
import { AuthGuard } from "app/modules/auth/services/auth-guard.service";

import { NotFoundPageComponent } from "./modules/core/pages/not-found-page.component";

export const routes: Routes = [
	{
		path: "",
		redirectTo: "dashboard/locations",
		pathMatch: "full",
	},
	{
		path: "dashboard",
		loadChildren: () =>
			import("app/modules/dashboard/dashboard.module").then(
				(module) => module.DashboardModule,
			),
		canActivate: [
			(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
				inject(AuthGuard).canActivate(route, state),
		],
	},
	{ path: "**", component: NotFoundPageComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: "legacy",
			scrollPositionRestoration: "enabled",
		} as ExtraOptions),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(private readonly _router: Router) {
		// this.router.events.subscribe((event) => {
		// 	if (event instanceof NavigationEnd) {
		// 		posthog.capture("$pageview");
		// 	}
		// });
	}
}
