import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NotFoundPageComponent } from "./pages/not-found-page.component";

export const COMPONENTS = [NotFoundPageComponent];

@NgModule({
	imports: [CommonModule, RouterModule],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [],
		};
	}
}
