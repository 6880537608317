import { Component, ChangeDetectionStrategy } from "@angular/core";

@Component({
	selector: "bc-not-found-page",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: ` Not Found `,
	styles: [
		`
			:host {
				text-align: center;
			}
		`,
	],
})
export class NotFoundPageComponent {}
