import { inject, NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import fr from "@angular/common/locales/fr-CA";
/** config ng-zorro-antd i18n **/
import { en_US, NZ_I18N, fr_CA } from "ng-zorro-antd/i18n";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {
	StoreRouterConnectingModule,
	RouterStateSerializer,
} from "@ngrx/router-store";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { SchemaFormModule } from "@elevatedsignals/es-ngx-schema-form";
import { Angulartics2Module } from "angulartics2";

import { CoreModule } from "./modules/core";
import { AuthModule } from "./modules/auth";
import {
	PERMISSIONS,
	APP_PERMISSIONS,
} from "./shared/permissions/permissions.constants";
import { reducers, metaReducers } from "./reducers";
import { CustomRouterStateSerializer } from "./shared/utils";
import { AppComponent } from "./app.component";
import { EnvServiceProvider } from "./shared/services/env.service.provider";
import { AppRoutingModule } from "./app-routing.module";
import { TranslocoRootModule } from "./transloco-root.module";
import { TrackingModule } from "./tracking/tracking.module";
import { Globals } from "./shared/modules/globals/globals.service";

registerLocaleData(en);
registerLocaleData(fr);
@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		/**
		 * Store devtools instrument the store retaining past versions of state
		 * and recalculating new states. This enables powerful time-travel
		 * debugging.
		 *
		 * To use the debugger, install the Redux Devtools extension for either
		 * Chrome or Firefox
		 *
		 * See: https://github.com/zalmoxisus/redux-devtools-extension
		 */
		// !environment.production ? StoreDevtoolsModule.instrument() : [],

		/**
		 * EffectsModule.forRoot() is imported once in the root module and
		 * sets up the effects class to be initialized immediately when the
		 * application starts.
		 *
		 * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
		 */
		EffectsModule.forRoot([]),

		CoreModule.forRoot(),
		AuthModule,
		LeafletModule,
		LeafletDrawModule,

		SchemaFormModule.forRoot(),

		AppRoutingModule,
		Angulartics2Module.forRoot(),
		/**
		 * StoreModule.forRoot is imported once in the root module, accepting a reducer
		 * function or object map of reducer functions. If passed an object of
		 * reducers, combineReducers will be run creating your application
		 * meta-reducer. This returns all providers for an @ngrx/store
		 * based application.
		 *
		 * TODO: Immutability was switched off due to sidenav issues, we need to figure out a better solution for this
		 * moving forward where we can keep immutability of the store.
		 */
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			},
		}),

		/**
		 * @ngrx/router-store keeps router state up-to-date in the store.
		 */
		StoreRouterConnectingModule.forRoot(),

		HttpClientModule,
		TranslocoRootModule,
		TrackingModule,
	],
	providers: [
		{
			provide: PERMISSIONS,
			useValue: APP_PERMISSIONS,
		},
		/**
		 * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
		 * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
		 * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
		 */
		{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
		EnvServiceProvider,
		{
			provide: NZ_I18N,
			useFactory: () => {
				const localId = inject(LOCALE_ID);
				switch (localId) {
					case "en":
						return en_US;
					/** keep the same with angular.json/i18n/locales configuration **/
					case "fr":
						return fr_CA;
					default:
						return en_US;
				}
			},
		},
		Globals,
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
})
export class AppModule {}
