import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { datadogRum } from "@datadog/browser-rum";
import { EnvService } from "app/shared/services/env.service";
import { TrackingService } from "./tracking.service";

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [TrackingService],
})
export class TrackingModule {
	constructor(private readonly _env: EnvService) {
		this.initializeTrackers();
	}

	initializeTrackers = () => {
		const shouldInitializeTracking =
			!window.location.host.includes("127.0.0.1") &&
			!window.location.host.includes("localhost") &&
			!window.location.host.includes("dashboard:4302");

		if (shouldInitializeTracking) {
			// Initialize Datadog
			datadogRum.init({
				applicationId: this._env.dd_rum_application_id!,
				clientToken: this._env.dd_rum_client_token!,
				// proxy: this._env.dd_rum_proxy,
				proxy: (options) =>
					`${this._env.dd_rum_proxy}${options.path}?${options.parameters}`,
				site: "datadoghq.com",
				service: "dashboard",
				env: this._env.dd_env,
				version: this._env.dd_version,
				sessionSampleRate: 100,
				sessionReplaySampleRate: 100,
				trackResources: true,
				trackLongTasks: true,
				trackUserInteractions: true,
				enablePrivacyForActionName: true,
				compressIntakeRequests: true,
				defaultPrivacyLevel: "allow",
			});
		}
	};
}
